import { json, redirect } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { CategoryCard } from '~/components/category-card';

import { Routes, withParams } from '~/constants/routes';
import { withAugmentedContext } from '~/services/auth/Guards';
import { formatCategoryEnum } from '~/utils/format';

export const loader = withAugmentedContext(async ({ context }) => {
  const { data } = await context.api.GET('/marketplace/categories');

  return json({ marketplaceCategories: data || [] });
});

export default function MarketplaceCategories() {
  const { marketplaceCategories } = useLoaderData<typeof loader>();

  return (
    <>
      {marketplaceCategories.map((category) => (
        <CategoryCard
          key={category.name}
          category={category}
          urlOverride={withParams(Routes.PublicMarketplaceCategory, {
            category: formatCategoryEnum(category.categorySlug.value),
          })}
        />
      ))}
    </>
  );
}
